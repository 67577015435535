import * as React from "react";
import { PrismicRichText } from "@prismicio/react";
import { Link, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import styled from "styled-components";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Slider from "../components/common/Slider";
import {
  Container,
  Row,
  SectionTitle,
  Column,
  FlexContainer,
} from "../components/common";

import hrImage from "../images/fancy_heading_hr.png";
import starImage from "../images/betheme/images/icons/list_star.png";
import textLine from "../images/betheme/images/stripes/textline.png";
import blockquoteImage from "../images/betheme/images/blockquote.png";

export const query = graphql`
  query HomepageQuery($lang: String) {
    prismicMisc(lang: { eq: $lang }) {
      data {
        room
        home
        contact
      }
    }
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      lang
      data {
        site_title {
          text
          html
        }
        covid_section_title {
          html
          richText
        }
        covid_section_content {
          html
          richText
        }
        health_safety_measures_section_title {
          html
          richText
        }
        health_safety_measures_section_content {
          richText
        }
        other_info {
          text
          richText
        }
        about_us_title {
          text
        }
        about_us_content {
          richText
        }
        what_people_say {
          text
          html
        }
        people_say_1 {
          text
          html
        }
        people_say_2 {
          text
          html
        }
        body {
          ... on PrismicHomepageDataBodyCardsCarousel {
            id
            primary {
              title {
                richText
              }
              description {
                richText
                text
              }
            }
            items {
              image {
                alt
                gatsbyImageData
                url
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicHomepageDataBodyBenefitsInclusiveServices {
            primary {
              title {
                text
                html
              }
              description {
                text
                richText
              }
            }
            items {
              points {
                text
                richText
              }
            }
          }
          ... on PrismicHomepageDataBodyOgInfo {
            primary {
              ogDescription: description
              ogTitle: title
              image {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const Section = styled.section`
  background: url(${hrImage});

  &:last-child {
    background: none;
  }
  background-position: bottom center;
  background-repeat: no-repeat;
  padding-bottom: 15px;
`;

const CovidSectionContentRow = styled(Row)`
  font-size: 1.2rem;
  color: ${props => props.theme.colors.dark};
`;

const HealthSafetyMeasuresSectionContentRow = styled(Row)`
  .description {
    font-size: 1.2rem;
    color: ${props => props.theme.colors.dark};
  }
  .underline {
    text-decoration: underline;
  }
`;

const WelcomeContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const Item = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 calc(33.33% - 20px);
  }
  flex: 0 0 100%;
  padding: 10px;
  margin: 10px;
  text-align: center;
`;

const ItemImageContainer = styled.div`
  border-color: rgb(245, 245, 245);
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1) inset;
  border-width: 8px;
  border-style: solid;
  text-align: center;
  img {
    margin: 0;
  }
`;

const StarList = styled.ul`
  margin: 0 0 15px 30px;
  list-style: none;
  margin-left: 0;
  color: #737e86;

  li {
    background: url(${starImage}) no-repeat 20px 5px;
    position: relative;
    margin: 0 0 20px 0;
    padding-left: 50px;
    font-weight: normal;
    &:last-child::after {
      background: none;
    }
    &::after {
      content: "";
      width: 70px;
      height: 1px;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.08);
      position: absolute;
      left: 0;
      bottom: -11px;
    }
  }
`;

const Blockquote = styled.blockquote`
  font-size: 17px;
  line-height: 31px;
  display: block;
  height: auto;
  margin: 0 0 35px 25px;
  top: 15px;
  position: relative;
  background: url(${textLine}) repeat-y;
  color: #444;

  &:after {
    content: "";
    width: 55px;
    height: 40px;
    background: url(${blockquoteImage});
    position: absolute;
    left: -25px;
    top: -15px;
  }
`;

const IndexPage = ({ data, pageContext }) => {
  const {
    site_title: siteTitle,
    covid_section_title: covidSectionTitle,
    covid_section_content: covidSectionContent,
    health_safety_measures_section_title: healthSafetyMeasuresSectionTitle,
    health_safety_measures_section_content: healthSafetyMeasuresSectionContent,
    about_us_title: aboutUsTitle,
    about_us_content: aboutUsContent,
    other_info: otherInfo,
    what_people_say: whatPeopleSay,
    people_say_1: peopleSay1,
    people_say_2: peopleSay2,
    body,
  } = data.prismicHomepage.data;
  const welcome = body[1];
  const benefits = body[0];
  const meta = body[2];
  return (
    <Layout lang={pageContext.lang} misc={data.prismicMisc.data}>
      <Seo
        title={meta.primary.ogTitle}
        description={meta.primary.ogDescription}
        ogImageUrl={meta.primary.image.url}
      />
      <Slider>
        <StaticImage
          src="../images/1.jpg"
          formats={["jpg"]}
          alt="Welcome Slider"
          style={{ marginBottom: `1.45rem` }}
        />
        <StaticImage
          src="../images/7.jpg"
          formats={["jpg"]}
          alt="Welcome Slider"
          style={{ marginBottom: `1.45rem` }}
        />
        <StaticImage
          src="../images/8.jpg"
          formats={["jpg"]}
          alt="Welcome Slider"
          style={{ marginBottom: `1.45rem` }}
        />
        <StaticImage
          src="../images/6.jpg"
          formats={["jpg"]}
          alt="Welcome Slider"
          style={{ marginBottom: `1.45rem` }}
        />
        <StaticImage
          src="../images/5.jpg"
          formats={["jpg"]}
          alt="Welcome Slider"
          style={{ marginBottom: `1.45rem` }}
        />
        <StaticImage
          src="../images/11.jpg"
          formats={["jpg"]}
          alt="Welcome Slider"
          style={{ marginBottom: `1.45rem` }}
        />
      </Slider>
      <Container>
        <Section>
          <Row style={{ textAlign: "center" }}>
            <PrismicRichText
              field={covidSectionTitle.richText}
            ></PrismicRichText>
          </Row>
          <CovidSectionContentRow>
            <PrismicRichText
              field={covidSectionContent.richText}
            ></PrismicRichText>
          </CovidSectionContentRow>
        </Section>
        <Section>
          <Row style={{ textAlign: "center" }}>
            <PrismicRichText
              field={healthSafetyMeasuresSectionTitle.richText}
            ></PrismicRichText>
          </Row>
          <HealthSafetyMeasuresSectionContentRow>
            <PrismicRichText
              field={healthSafetyMeasuresSectionContent.richText}
            ></PrismicRichText>
          </HealthSafetyMeasuresSectionContentRow>
        </Section>
        <Section>
          <Row style={{ textAlign: "center" }}>
            <PrismicRichText
              field={welcome.primary.title.richText}
            ></PrismicRichText>
          </Row>
          <Row style={{ textAlign: "center", fontSize: "1.2em" }}>
            <PrismicRichText
              field={welcome.primary.description.richText}
            ></PrismicRichText>
          </Row>
          <Row>
            <WelcomeContainer>
              {welcome.items.map(item => (
                <Item>
                  <h4>{item.title.text}</h4>
                  <ItemImageContainer>
                    <img src={item.image.url} alt={item.image.alt}></img>
                  </ItemImageContainer>
                </Item>
              ))}
            </WelcomeContainer>
          </Row>
        </Section>
        <Section>
          <Row style={{ textAlign: "center", marginTop: "16px" }}>
            <PrismicRichText field={otherInfo.richText}></PrismicRichText>
          </Row>
          <FlexContainer>
            <Column size={3} gap="20px">
              <h2>{aboutUsTitle.text}</h2>
              <StaticImage
                src="../images/1.jpg"
                formats={["jpg"]}
                alt="About Us"
                style={{
                  marginBottom: `1.45rem`,
                  maxWidth: "90%",
                }}
              />
              <PrismicRichText
                field={aboutUsContent.richText}
              ></PrismicRichText>
            </Column>
            <Column size={3} gap="20px">
              <h2>{benefits.primary.title.text}</h2>
              <big>
                <PrismicRichText
                  field={benefits.primary.description.richText}
                ></PrismicRichText>
              </big>
              <StarList>
                {benefits.items.map(item => {
                  return (
                    <li>
                      <PrismicRichText
                        field={item.points.richText}
                      ></PrismicRichText>
                    </li>
                  );
                })}
              </StarList>
            </Column>
            <Column size={3} gap="20px">
              <h2>{whatPeopleSay.text}</h2>
              <Blockquote>{peopleSay1.text}</Blockquote>
              <Blockquote>{peopleSay2.text}</Blockquote>
            </Column>
          </FlexContainer>
        </Section>
      </Container>
    </Layout>
  );
};

export default withPrismicPreview(IndexPage);
